iframe {
  display: none !important;
}

.openeditem {
  position: relative;
  padding: 15px;
}
.closeBtnOuter {
  display: flex;
  justify-content: flex-end;
}
.openeditem .closeButton {
  cursor: pointer;
  transition: 0.3s all;
  /* position: absolute;
right: 30px;
top: 30px; */
  background-color: #efefef;
  color: #000;
  /* padding: 10px; */
  height: 40px;
  width: 40px;
  border-radius: 100%;
  text-align: center;
  border: 0;
  box-shadow: none;
  font-size: 16px;
  font-weight: 600;
}

.openeditem .closeButton:hover {
  background-color: #000;
  color: #fff;
}

.centeredImg {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 15px;
}
.centeredImg img {
  border-radius: 4px;
}
.centeredImg video {
  height: 300px;
  border-radius: 4px;
  object-fit: fill;
  width: 450px;
}
.videoStyles {
  height: 300px;
  border-radius: 4px;
  object-fit: fill;
  width: 450px;
}

.likeItems__style {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  /* color: #000; */
}
.likeItems__key {
  font-size: 14px;
  font-weight: 500;
}
.likeItems__value {
  font-size: 12px;
  font-weight: 400;
}

.customStyleIcons {
  max-width: 25px;
  width: 100%;
}
svg.customStyleIcons {
  max-width: 25px;
  width: 25px;
  height: 25px;
}

.MuiCardContent-root video {
  height: 300px;
  border-radius: 4px;
  object-fit: cover;
  /* max-width: 450px; */
  width: 100%;
}

.customTableHeight {
  max-height: 700px;
}
.customFormStyle {
  margin: 0 auto;
}

.customFormStyles {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.customFormStyles > div {
  max-width: 48%;
  flex: 0 0 48%;
}
.customFormStyles > div > div {
  margin: 0 !important;
  width: 100% !important;
  display: block !important;
}
.customFormStyles > div textarea {
  width: 100%;
  padding: 10px 5px;
}

.customFormStyles > div:last-child {
  max-width: 100%;
  flex: 1 1 100%;
}
.customFormStyles > div:nth-child(7) {
  max-width: 100%;
  flex: 1 1 100%;
}

.customFormStyles > div button {
  margin-right: 20px;
}

td,
th {
  white-space: nowrap;
}
.hideShowIcon {
  width: 45px;
  position: absolute;
  right: 0;
}

.userDropDown .MuiMenuItem-root {
  padding: 10px 30px;
}

.dialogCustom h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dialogCustom h2 button {
  padding: 0;
}

@media (max-width: 1600px) {
  .socialStstsCustom .MuiTypography-h3 {
    font-size: 15px;
  }
}
@media (max-width: 1400px) {
  .socialStstsCustom .MuiTypography-h3 {
    font-size: 13px;
  }
}

.eyeFieldStyle {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  right: 15px;
}
.inputPasswordCustom {
  position: relative;
}

.eyeFieldStyle .hideShowIcon {
  width: 25px;
}
.inputPasswordCustom input {
  padding-right: 55px;
}

.dialogCustom.closeIconRight h2 {
  justify-content: flex-end;
}

.makeStyles-perfectScrollbarGerneralInfo-81, .makeStyles-perfectScrollbarNewsFeed-150, .makeStyles-perfectScrollbarActives-172
/* ,.scrollbar-container  */
 {
  height: calc(100vh - 135px) !important;
}

.slick-arrow {
  background-color: transparent !important;
  border-radius: 100%;
  width: 40px !important;
  height: 40px !important;
}
.slick-arrow::before {
  color: #fff !important;
  font-size: 40px !important;
}
.makeStyles-cancelBtn-183 .MuiSvgIcon-root {
  width: 40px;
  height: 40px;
}

.customScrollStyle {
  padding-right: 12px;
}

.customScrollStyle .makeStyles-subTitleRoot-131 {
  max-width: 200px;
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.customHeightSetting .scrollbar-container {
  height: calc(100vh - 205px) !important;
}

.inputSearchCustom input:focus {
  width: 100% !important;
}
@media (max-width: 1400px) {
  .inputSearchCustom input:focus {
    width: 100% !important;
  }
}
@media (max-width: 1400px) {
  .inputSearchCustom input:focus {
    width: 100% !important;
    background-color: #fff !important;
  }
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  height: 300px !important;
}

.rems {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}
.rems input[type="checkbox"] {
  margin-right: 9px;
}

.fixStyleImg {
  min-height: 300px;
  width: 100%;
  height: 300px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
